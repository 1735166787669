<template>
  <div class="g-wrap">
    <!-- <column-header :data="{ name: '企业简介' }"></column-header> -->
    <vue-aliplayer-v2
      v-if="enterpriseInfo.PromotionalVideoFullPath"
      :source="enterpriseInfo.PromotionalVideoFullPath"
      ref="VueAliplayerV2"
      style="width:750px;height:425px;margin:32px 16px;"
    />
    <div class="content" v-html="enterpriseInfo.Introduction"></div>
  </div>
</template>

<script>
import ColumnHeader from "@/components/ColumnHeader2";
export default {
  components: {
    ColumnHeader
  },
  props: {
    enterpriseInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      content: `顺丰科技有限公司隶属于顺丰集团，成立于2009年，主要致力于带领物流行业进入智能化、数字化、可视化、精细化新时代，以创新技术和数据为驱动力，为企业提供端到端一体化的供应链解决方案和服务，协助客户供应链优化和转型升级。具备
      “深圳市重点软件企业”、“国家高新技术企业”等资质，获得2019《麻省理工科技评论》“50家聪明的公司”、2019中国智慧物流十大创新物流引领企业等荣誉称号。
      坚持自主创新，实现创新技术驱动物流行业升级。深耕无人机及自动化、大数据及产品、人工智能及应用、精准地图平台、智能化设备、智慧硬件、综合物流解决方案等领域，拥有1982项专利，35PB+业务数据，在中国物流科技行业处于领先地位。
      开放合作，汇聚全球人才。与美国佐治亚理工学院、哈尔滨工业大学、北京航空航天大学、上海交通大……顺丰科技有限公司隶属于顺丰集团，成立于2009年，主要致力于带领物流行业进入智能化、数字化、可视化、精细化新时代，以创新技术和数据为驱动力，为企业提供端到端一体化的供应链解决方案和服务，协助客户供应链优化和转型升级。具备
      “深圳市重点软件企业”、“国家高新技术企业”等资质，获得2019《麻省理工科技评论》“50家聪明的公司”、2019中国智慧物流十大创新物流引领企业等荣誉称号。
      坚持自主创新，实现创新技术驱动物流行业升级。深耕无人机及自动化、大数据及产品、人工智能及应用、精准地图平台、智能化设备、智慧硬件、综合物流解决方案等领域，拥有1982项专利，35PB+业务数据，在中国物流科技行业处于领先地位。
      开放合作，汇聚全球人才。与美国佐治亚理工学院、哈尔滨工业大学、北京航空航天大学、上海交通大……顺丰科技有限公司隶属于顺丰集团，成立于2009年，主要致力于带领物流行业进入智能化、数字化、可视化、精细化新时代，以创新技术和数据为驱动力，为企业提供端到端一体化的供应链解决方案和服务，协助客户供应链优化和转型升级。具备
      “深圳市重点软件企业”、“国家高新技术企业”等资质，获得2019《麻省理工科技评论》“50家聪明的公司”、2019中国智慧物流十大创新物流引领企业等荣誉称号。
      坚持自主创新，实现创新技术驱动物流行业升级。深耕无人机及自动化、大数据及产品、人工智能及应用、精准地图平台、智能化设备、智慧硬件、综合物流解决方案等领域，拥有1982项专利，35PB+业务数据，在中国物流科技行业处于领先地位。
      开放合作，汇聚全球人才。与美国佐治亚理工学院、哈尔滨工业大学、北京航空航天大学、上海交通大……顺丰科技有限公司隶属于顺丰集团，成立于2009年，主要致力于带领物流行业进入智能化、数字化、可视化、精细化新时代，以创新技术和数据为驱动力，为企业提供端到端一体化的供应链解决方案和服务，协助客户供应链优化和转型升级。具备
      “深圳市重点软件企业”、“国家高新技术企业”等资质，获得2019《麻省理工科技评论》“50家聪明的公司”、2019中国智慧物流十大创新物流引领企业等荣誉称号。
      坚持自主创新，实现创新技术驱动物流行业升级。深耕无人机及自动化、大数据及产品、人工智能及应用、精准地图平台、智能化设备、智慧硬件、综合物流解决方案等领域，拥有1982项专利，35PB+业务数据，在中国物流科技行业处于领先地位。
      开放合作，汇聚全球人才。与美国佐治亚理工学院、哈尔滨工业大学、北京航空航天大学、上海交通大……顺丰科技有限公司隶属于顺丰集团，成立于2009年，主要致力于带领物流行业进入智能化、数字化、可视化、精细化新时代，以创新技术和数据为驱动力，为企业提供端到端一体化的供应链解决方案和服务，协助客户供应链优化和转型升级。具备
      “深圳市重点软件企业”、“国家高新技术企业”等资质，获得2019《麻省理工科技评论》“50家聪明的公司”、2019中国智慧物流十大创新物流引领企业等荣誉称号。
      坚持自主创新，实现创新技术驱动物流行业升级。深耕无人机及自动化、大数据及产品、人工智能及应用、精准地图平台、智能化设备、智慧硬件、综合物流解决方案等领域，拥有1982项专利，35PB+业务数据，在中国物流科技行业处于领先地位。
      开放合作，汇聚全球人才。与美国佐治亚理工学院、哈尔滨工业大学、北京航空航天大学、上海交通大……顺丰科技有限公司隶属于顺丰集团，成立于2009年，主要致力于带领物流行业进入智能化、数字化、可视化、精细化新时代，以创新技术和数据为驱动力，为企业提供端到端一体化的供应链解决方案和服务，协助客户供应链优化和转型升级。具备
      “深圳市重点软件企业”、“国家高新技术企业”等资质，获得2019《麻省理工科技评论》“50家聪明的公司”、2019中国智慧物流十大创新物流引领企业等荣誉称号。
      坚持自主创新，实现创新技术驱动物流行业升级。深耕无人机及自动化、大数据及产品、人工智能及应用、精准地图平台、智能化设备、智慧硬件、综合物流解决方案等领域，拥有1982项专利，35PB+业务数据，在中国物流科技行业处于领先地位。
      开放合作，汇聚全球人才。与美国佐治亚理工学院、哈尔滨工业大学、北京航空航天大学、上海交通大……顺丰科技有限公司隶属于顺丰集团，成立于2009年，主要致力于带领物流行业进入智能化、数字化、可视化、精细化新时代，以创新技术和数据为驱动力，为企业提供端到端一体化的供应链解决方案和服务，协助客户供应链优化和转型升级。具备
      “深圳市重点软件企业”、“国家高新技术企业”等资质，获得2019《麻省理工科技评论》“50家聪明的公司”、2019中国智慧物流十大创新物流引领企业等荣誉称号。
      坚持自主创新，实现创新技术驱动物流行业升级。深耕无人机及自动化、大数据及产品、人工智能及应用、精准地图平台、智能化设备、智慧硬件、综合物流解决方案等领域，拥有1982项专利，35PB+业务数据，在中国物流科技行业处于领先地位。
      开放合作，汇聚全球人才。与美国佐治亚理工学院、哈尔滨工业大学、北京航空航天大学、上海交通大……顺丰科技有限公司隶属于顺丰集团，成立于2009年，主要致力于带领物流行业进入智能化、数字化、可视化、精细化新时代，以创新技术和数据为驱动力，为企业提供端到端一体化的供应链解决方案和服务，协助客户供应链优化和转型升级。具备
      “深圳市重点软件企业”、“国家高新技术企业”等资质，获得2019《麻省理工科技评论》“50家聪明的公司”、2019中国智慧物流十大创新物流引领企业等荣誉称号。
      坚持自主创新，实现创新技术驱动物流行业升级。深耕无人机及自动化、大数据及产品、人工智能及应用、精准地图平台、智能化设备、智慧硬件、综合物流解决方案等领域，拥有1982项专利，35PB+业务数据，在中国物流科技行业处于领先地位。
      开放合作，汇聚全球人才。与美国佐治亚理工学院、哈尔滨工业大学、北京航空航天大学、上海交通大……顺丰科技有限公司隶属于顺丰集团，成立于2009年，主要致力于带领物流行业进入智能化、数字化、可视化、精细化新时代，以创新技术和数据为驱动力，为企业提供端到端一体化的供应链解决方案和服务，协助客户供应链优化和转型升级。具备
      “深圳市重点软件企业”、“国家高新技术企业”等资质，获得2019《麻省理工科技评论》“50家聪明的公司”、2019中国智慧物流十大创新物流引领企业等荣誉称号。
      坚持自主创新，实现创新技术驱动物流行业升级。深耕无人机及自动化、大数据及产品、人工智能及应用、精准地图平台、智能化设备、智慧硬件、综合物流解决方案等领域，拥有1982项专利，35PB+业务数据，在中国物流科技行业处于领先地位。
      开放合作，汇聚全球人才。与美国佐治亚理工学院、哈尔滨工业大学、北京航空航天大学、上海交通大……顺丰科技有限公司隶属于顺丰集团，成立于2009年，主要致力于带领物流行业进入智能化、数字化、可视化、精细化新时代，以创新技术和数据为驱动力，为企业提供端到端一体化的供应链解决方案和服务，协助客户供应链优化和转型升级。具备
      “深圳市重点软件企业”、“国家高新技术企业”等资质，获得2019《麻省理工科技评论》“50家聪明的公司”、2019中国智慧物流十大创新物流引领企业等荣誉称号。
      坚持自主创新，实现创新技术驱动物流行业升级。深耕无人机及自动化、大数据及产品、人工智能及应用、精准地图平台、智能化设备、智慧硬件、综合物流解决方案等领域，拥有1982项专利，35PB+业务数据，在中国物流科技行业处于领先地位。
      开放合作，汇聚全球人才。与美国佐治亚理工学院、哈尔滨工业大学、北京航空航天大学、上海交通大……顺丰科技有限公司隶属于顺丰集团，成立于2009年，主要致力于带领物流行业进入智能化、数字化、可视化、精细化新时代，以创新技术和数据为驱动力，为企业提供端到端一体化的供应链解决方案和服务，协助客户供应链优化和转型升级。具备
      “深圳市重点软件企业”、“国家高新技术企业”等资质，获得2019《麻省理工科技评论》“50家聪明的公司”、2019中国智慧物流十大创新物流引领企业等荣誉称号。
      坚持自主创新，实现创新技术驱动物流行业升级。深耕无人机及自动化、大数据及产品、人工智能及应用、精准地图平台、智能化设备、智慧硬件、综合物流解决方案等领域，拥有1982项专利，35PB+业务数据，在中国物流科技行业处于领先地位。
      开放合作，汇聚全球人才。与美国佐治亚理工学院、哈尔滨工业大学、北京航空航天大学、上海交通大……顺丰科技有限公司隶属于顺丰集团，成立于2009年，主要致力于带领物流行业进入智能化、数字化、可视化、精细化新时代，以创新技术和数据为驱动力，为企业提供端到端一体化的供应链解决方案和服务，协助客户供应链优化和转型升级。具备
      “深圳市重点软件企业”、“国家高新技术企业”等资质，获得2019《麻省理工科技评论》“50家聪明的公司”、2019中国智慧物流十大创新物流引领企业等荣誉称号。
      坚持自主创新，实现创新技术驱动物流行业升级。深耕无人机及自动化、大数据及产品、人工智能及应用、精准地图平台、智能化设备、智慧硬件、综合物流解决方案等领域，拥有1982项专利，35PB+业务数据，在中国物流科技行业处于领先地位。
      开放合作，汇聚全球人才。与美国佐治亚理工学院、哈尔滨工业大学、北京航空航天大学、上海交通大……顺丰科技有限公司隶属于顺丰集团，成立于2009年，主要致力于带领物流行业进入智能化、数字化、可视化、精细化新时代，以创新技术和数据为驱动力，为企业提供端到端一体化的供应链解决方案和服务，协助客户供应链优化和转型升级。具备
      “深圳市重点软件企业”、“国家高新技术企业”等资质，获得2019《麻省理工科技评论》“50家聪明的公司”、2019中国智慧物流十大创新物流引领企业等荣誉称号。
      坚持自主创新，实现创新技术驱动物流行业升级。深耕无人机及自动化、大数据及产品、人工智能及应用、精准地图平台、智能化设备、智慧硬件、综合物流解决方案等领域，拥有1982项专利，35PB+业务数据，在中国物流科技行业处于领先地位。
      开放合作，汇聚全球人才。与美国佐治亚理工学院、哈尔滨工业大学、北京航空航天大学、上海交通大……顺丰科技有限公司隶属于顺丰集团，成立于2009年，主要致力于带领物流行业进入智能化、数字化、可视化、精细化新时代，以创新技术和数据为驱动力，为企业提供端到端一体化的供应链解决方案和服务，协助客户供应链优化和转型升级。具备
      “深圳市重点软件企业”、“国家高新技术企业”等资质，获得2019《麻省理工科技评论》“50家聪明的公司”、2019中国智慧物流十大创新物流引领企业等荣誉称号。
      坚持自主创新，实现创新技术驱动物流行业升级。深耕无人机及自动化、大数据及产品、人工智能及应用、精准地图平台、智能化设备、智慧硬件、综合物流解决方案等领域，拥有1982项专利，35PB+业务数据，在中国物流科技行业处于领先地位。
      开放合作，汇聚全球人才。与美国佐治亚理工学院、哈尔滨工业大学、北京航空航天大学、上海交通大……顺丰科技有限公司隶属于顺丰集团，成立于2009年，主要致力于带领物流行业进入智能化、数字化、可视化、精细化新时代，以创新技术和数据为驱动力，为企业提供端到端一体化的供应链解决方案和服务，协助客户供应链优化和转型升级。具备
      “深圳市重点软件企业”、“国家高新技术企业”等资质，获得2019《麻省理工科技评论》“50家聪明的公司”、2019中国智慧物流十大创新物流引领企业等荣誉称号。
      坚持自主创新，实现创新技术驱动物流行业升级。深耕无人机及自动化、大数据及产品、人工智能及应用、精准地图平台、智能化设备、智慧硬件、综合物流解决方案等领域，拥有1982项专利，35PB+业务数据，在中国物流科技行业处于领先地位。
      开放合作，汇聚全球人才。与美国佐治亚理工学院、哈尔滨工业大学、北京航空航天大学、上海交通大……`
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.g-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  .content {
    width: 100%;
    text-indent: 2em;
    font-size: 14px;
    margin-top: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 28px;
    margin-bottom: 40px;
  }
}
</style>
