<template>
  <div class="g-wrap">
    <div class="list">
      <div
        class="post-card"
        v-for="(item, index) in list"
        :key="index"
        @click="handleGo(item)"
      >
        <div class="top">
          <span class="title">{{item.Name}}</span>
          <span class="salary">{{`${item.SalaryMin}-${item.SalaryMax}K/月`}}</span>
        </div>
        <div class="bottom">
          <span class="tag">{{`${item.RecruitTypeDescription} | ${item.ExperienceDescription} | 招${item.RecruitCount}人`}}</span>
          <span class="month">{{item.LastRefreshTime|dateFilter}}</span>
        </div>
      </div>
    </div>
      <div class="loading">
        <div v-if="noMore" class="color-light">—— 没有更多了 ——</div>
        <el-button v-else @click="load" plain type="primary" :loading="loading">{{loading?'加载中':'加载更多'}}</el-button>
      </div>
  </div>
</template>

<script>
import { getPageList } from "@/api/job"; 
import {formartDate } from "@/utils/tools"
export default {
  components: {},
  data() {
    return {
      loading: false,
      list: [],
      totalCount: null,
      KeyWord:"",
      pageIndex:0,
    };
  },
  filters:{
    dateFilter(date){
      return formartDate(date,'date')
    }
  },
  computed: {
    noMore() {
      return this.loading == false && this.list.length == this.totalCount;
    }
  },
  created() {
    this.pageIndex = 0
    this.load()
  },
  methods: {
    handleGo(item) {
      // this.$message.success('岗位详情请在微信搜索“广东轻工职业技术学院”后在小程序中查看！')
      // this.$router.push(
      //   "/cooperation/home-enterprise/post-detail?id=" + item.Id
      // );
    },
    load() {
      this.pageIndex++;
      var query = {
        PageSize: 6,
        PageIndex: this.pageIndex,
        KeyWord: this.KeyWord,
        UnitId:this.$route.query.id,
        UnitType:2,
        ShowAll:true

      };
      getPageList(query).then(res => {
        if (res.IsSuccess) {
          this.list = this.list.concat(res.Result.Data);
          this.totalCount = res.Result.TotalCount;
          this.loading = false;
          console.log(this.totalCount,this.list.length)
        }
      });
    },
    
  }
};
</script>

<style lang="scss" scoped>
.g-wrap {
  display: flex;
  flex-direction: column;
  .loading {
    margin-bottom: 40px;
    text-align: center;
  }
  .list {
    width: 100%;
    padding: 40px 0 24px 0;
    display: flex;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .post-card {
      width: 542px;
      padding: 24px;
      box-sizing: border-box;
      margin: 0 16px 16px 0;

      background: #ffffff;
      box-shadow: 0px 4px 12px 0px rgba(51, 51, 51, 0.16);
      border-radius: 8px;
      &:nth-of-type(2n) {
        margin-right: 0;
      }
      &:hover {
        cursor: pointer;
        box-shadow: 0px 4px 12px 0px rgba(51, 51, 51, 0.3);
        .top .title {
          color: #598DF3;
        }
      }
      .top,
      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        .title {
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 28px;
        }
        .salary {
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ff6010;
          line-height: 28px;
        }
      }
      .bottom {
        margin-top: 8px;
      }
    }
  }
}
</style>
